import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../utils/styles"
import PortfolioItem from "../PortfolioItem/PortfolioItem"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const PortfolioPreview = () => {
  const {
    allPrismicIndexBodyPortfolio: { nodes },
  } = useStaticQuery(PORTFOLIO_QUERY)
  return (
    <Container>
      {nodes.map((item, index) => {
        return <PortfolioItem item={item} key={index} />
      })}
    </Container>
  )
}

export default PortfolioPreview

const PORTFOLIO_QUERY = graphql`
  query portfolioQuery {
    allPrismicIndexBodyPortfolio {
      nodes {
        primary {
          subtitle {
            text
          }
          label {
            text
          }
          portfolio_title {
            text
          }
          logo_image {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          background_color
          link {
            url
          }
        }
      }
    }
  }
`
