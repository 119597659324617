import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import EvenSplitContainer from "../shared/EvenSplitContainer"
import { graphql, useStaticQuery } from "gatsby"
import { breakpoints } from "../../utils/styles"

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  box-sizing: border-box;

  @media (min-width: ${breakpoints.xl}) {
    padding: 2rem;
    justify-content: center;
  }
`

const AboutImg = styled(Img)``

const Title = styled.h4`
  font-weight: 700;
  font-family: var(--header-font);
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 2.5rem;
  }
  @media (min-width: ${breakpoints.xxl}) {
    font-size: 3.5rem;
  }
`

const Body = styled.div`
  margin-top: 1rem;

  p {
    font-size: 1.25rem;
    font-family: var(--body-font);
    line-height: 1.5;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 1.5rem;
    }
  }

  a {
    color: #000;

    :hover {
      text-decoration: none;
    }
  }
`

const SignatureContainer = styled.div`
  display: flex;
  align-items: center;
`

const Thanks = styled.p`
  font-weight: 700;
  font-family: var(--header-font);
  font-size: 1.625rem;
`

const AboutSection = ({ title, body, image }) => {
  const { signatureQuery } = useStaticQuery(SIGNATURE_QUERY)
  return (
    <EvenSplitContainer>
      <TextContainer>
        <Title>{title.text}</Title>
        <Body dangerouslySetInnerHTML={{ __html: body.html }} />
        <SignatureContainer>
          <Img fixed={signatureQuery.childImageSharp.fixed} />
          <Thanks>CHEERS!</Thanks>
        </SignatureContainer>
      </TextContainer>
      <AboutImg fluid={image.localFile.childImageSharp.fluid} />
    </EvenSplitContainer>
  )
}

export default AboutSection

const SIGNATURE_QUERY = graphql`
  query signatureQuery {
    signatureQuery: file(relativePath: { eq: "dalip-signature-black.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 50) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
