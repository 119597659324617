import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import PortfolioPreview from "../components/PortfolioPreview/PortfolioPreview"
import AboutSection from "../components/AboutSection/AboutSection"
import Collage from "../components/Collage/Collage"
import SectionContainer from "../components/shared/SectionContainer"
import TextContainer from "../components/shared/TextContainer"
import SectionTitle from "../components/shared/SectionTitle"
import SectionSubtitle from "../components/shared/SectionSubtitle"
import SignUpForm from "../components/Form/SignUpForm"
import JoinSection from "../components/JoinSection/JoinSection"

const IndexPage = props => {
  const {
    data: {
      prismicIndex: { data },
    },
    location,
  } = props
  return (
    <Layout path={location.pathname}>
      <SEO title="Home" />
      <Hero
        img={data.hero_image.localFile}
        title={data.title.text}
        tagline={data.tagline.text}
      />
      <SectionContainer background={`#fff`} id="sign-up">
        <TextContainer>
          <SectionTitle>It's a pleasure.</SectionTitle>
          <SectionSubtitle>
            Around here, you'll find some business activity and news highlights.
            You'll learn that I may be able to help your startup, so definitely
            feel free to reach out.
          </SectionSubtitle>
        </TextContainer>
        <SignUpForm />
      </SectionContainer>
      <SectionContainer background={`#000`}>
        <TextContainer>
          <SectionTitle color="#fff">Latest Activity</SectionTitle>
          <SectionSubtitle>Recent movements in the portfolio.</SectionSubtitle>
          <Link
            to="/portfolio"
            style={{
              color: "#fff",
              textTransform: "uppercase",
              fontFamily: "var(--subheader-font)",
              marginTop: "1.5rem",
              display: "inline-block",
            }}
          >
            View full portfolio details
          </Link>
        </TextContainer>
      </SectionContainer>
      <PortfolioPreview />
      <AboutSection
        title={data.about_title}
        body={data.about_body}
        image={data.about_image}
      />
      <Collage
        rect_image={data.rect_image}
        textbox={data.textbox}
        square_image={data.square_image}
        big_image={data.big_image}
      />
      <JoinSection />
    </Layout>
  )
}

export default IndexPage

export const fluidImageFragment = graphql`
  fragment fluidImageFragment on File {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

export const indexQuery = graphql`
  query indexQuery {
    prismicIndex {
      data {
        hero_image {
          localFile {
            ...fluidImageFragment
          }
        }
        title {
          text
        }
        tagline {
          text
        }
        about_title {
          text
        }
        about_body {
          html
        }
        about_image {
          localFile {
            ...fluidImageFragment
          }
        }
        square_image {
          localFile {
            ...fluidImageFragment
          }
        }
        textbox {
          text
        }
        rect_image {
          localFile {
            ...fluidImageFragment
          }
        }
        big_image {
          localFile {
            ...fluidImageFragment
          }
        }
      }
    }
  }
`
