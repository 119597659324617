import React from "react"
import TextContainer from "../shared/TextContainer"
import SectionTitle from "../shared/SectionTitle"
import SectionSubtitle from "../shared/SectionSubtitle"
import SignUpForm from "../Form/SignUpForm"
import SectionContainer from "../shared/SectionContainer"

const JoinSection = () => {
  return (
    <SectionContainer background={`#f6f6f6`}>
      <TextContainer>
        <SectionTitle>Join 5000+ others</SectionTitle>
        <SectionSubtitle>
          Localized monthly tips & thoughts. <br /> But also, makes for a great
          reminder of when you need me the most.
        </SectionSubtitle>
      </TextContainer>
      <SignUpForm />
    </SectionContainer>
  )
}

export default JoinSection
