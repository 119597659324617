import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { breakpoints } from "../../utils/styles"

const WebLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`

const Container = styled.div`
  background: ${props => props.bg && props.bg};
  color: ${props => (props.bg === "#ffffff" ? "#000" : "#fff")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: ${breakpoints.lg}) {
    padding: 3rem 1rem;
  }
`

const Ribbon = styled.div`
  position: absolute;
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  background: #2c7;
  color: #fff;
  font-family: var(--subheader-font);
  padding: 0.5rem;
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
`

const ImgContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: 300px;

  @media (min-width: ${breakpoints.md}) {
    max-width: 200px;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 225px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 300px;
  }
`
const Title = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 1.75rem;
  }
`

const Subtitle = styled.p`
  font-size: 1.25rem;
`

const Label = styled.div`
  background: ${props => (props.bg === "#ffffff" ? "#a0f47f" : "#fff")};
  color: ${props => (props.bg === "#ffffff" ? "#000" : props.bg)};
  padding: 0.5rem 0.75rem;
`

const PortfolioItem = ({ item }) => {
  const {
    portfolio_title,
    subtitle,
    logo_image,
    background_color,
    link,
    ribbon_text,
  } = item.primary

  return (
    <WebLink href={link.url} target="_blank">
      <Container bg={background_color}>
        {ribbon_text !== undefined && ribbon_text.text !== null && (
          <Ribbon>{ribbon_text.text}</Ribbon>
        )}
        <ImgContainer>
          <Img
            fluid={logo_image.localFile.childImageSharp.fluid}
            backgroundColor={background_color}
          />
        </ImgContainer>
        <Title>{portfolio_title.text}</Title>
        <Subtitle>{subtitle.text}</Subtitle>
        {item.primary.label && (
          <Label bg={background_color}>{item.primary.label.text}</Label>
        )}
      </Container>
    </WebLink>
  )
}

export default PortfolioItem
