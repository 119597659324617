import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { backgroundGatsbyImage, breakpoints } from "../../utils/styles"
import { Tagline, Title } from "../shared/Headers"
import scrollTo from "gatsby-plugin-smoothscroll"

const HeroContainer = styled.div`
  position: relative;
  height: 70vh;
  display: flex;

  @media (min-width: ${breakpoints.sm}) {
    min-height: 500px;
  }
`
const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 800px;
  width: 100%;
  padding: 1rem 2rem;
  margin-bottom: 3rem;

  @media (min-width: ${breakpoints.md}) {
    justify-content: center;
    margin-bottom: 0;
  }
`

const ScrollerRoot = styled.div`
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 2rem;
  width: 34px;
  height: 55px;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: initial;
  }
`

const ScrollMouse = styled.div`
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  cursor: pointer;
`

const Scroller = styled.div`
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;

  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;
    }
  }
`

const Hero = ({ img, title, tagline }) => {
  return (
    <HeroContainer>
      <Img
        fluid={img.childImageSharp.fluid}
        style={backgroundGatsbyImage}
        imgStyle={{ objectPosition: "75% 50%" }}
      />
      <TextContainer>
        <Title>{title}</Title>
        <Tagline>{tagline}</Tagline>
      </TextContainer>
      <ScrollerRoot>
        <ScrollMouse onClick={() => scrollTo("#sign-up")}>
          <Scroller />
        </ScrollMouse>
      </ScrollerRoot>
    </HeroContainer>
  )
}

export default Hero
