import styled from "styled-components"
import { breakpoints } from "../../utils/styles"

export const Title = styled.h1`
  color: #fff;
  font-size: 2.5rem;
  margin: 0;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}) {
    font-size: 3.5rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 4rem;
  }

  @media (min-width: ${breakpoints.mdxl}) {
    font-size: 5rem;
  }
`

export const Tagline = styled.h3`
  color: #fff;
  font-family: var(--body-font);
  font-size: 1.25rem;
  margin: 1rem 0 0;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.5rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 1.75rem;
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 2rem;
  }
`
