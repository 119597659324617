import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../utils/styles"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: 1fr 1fr;
  }
`

const EvenSplitContainer = ({ children, className }) => {
  return <Container className={className}>{children}</Container>
}

export default EvenSplitContainer
