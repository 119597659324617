import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { breakpoints } from "../../utils/styles"

const CollageRoot = styled.div`
  display: grid;
  grid-template-areas:
    "square"
    "textbox"
    "rect" "big";
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto, 4);

  @media (min-width: ${breakpoints.lg}) {
    grid-template-areas:
      "square textbox big big"
      "rect rect big big";
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr min-content;
  }
`

const Square = styled(Img)`
  grid-area: square;
`
const Textbox = styled.div`
  text-transform: uppercase;
  grid-area: textbox;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 5rem;
  font-family: var(--header-font);
  padding: 3rem;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3.5rem;
  }

  @media (min-width: ${breakpoints.mdxl}) {
    font-size: 5rem;
  }
`
const Big = styled(Img)`
  grid-area: big;
`
const Rect = styled(Img)`
  grid-area: rect;
`

const Collage = ({ square_image, textbox, big_image, rect_image }) => {
  return (
    <CollageRoot>
      <Square fluid={square_image.localFile.childImageSharp.fluid} />
      <Textbox>{textbox.text}</Textbox>
      <Big fluid={big_image.localFile.childImageSharp.fluid} />
      <Rect fluid={rect_image.localFile.childImageSharp.fluid} />
    </CollageRoot>
  )
}

export default Collage
